import React, { useState, useRef, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { useTriggerTransition } from '@mjso/gatsby-plugin-transition-link';
import clamp from 'lodash-es/clamp'; // usage: clamp(value, min, max)
import { AppContext } from '../AppContext';
import { LocaleContext } from '../components/Layout';
import Div100vh from 'react-div-100vh';
import { navigate } from 'gatsby';

import SEO from '../components/SEO';
import Reportage from '../components/Home/HomeReportage';
import Corporate from '../components/Home/HomeCorporate';
import GalleryIndexPreview from '../components/GalleryIndexPreview';
import DragHint from '../components/DragHint/DragHint';

import '../styles/main.scss';

const IndexPage = ({ data, entry, location }) => {
  // For applying styles after the animations are completed
  const [loadingAnimationsCompleted, setLoadingAnimationsCompleted] = useState(
    false
  );

  const [paceDone, setPaceDone] = useState(
    typeof window !== 'undefined' &&
      document.body.classList.contains('pace-done')
  );

  const [fadeHome, setFadeHome] = useState(false);

  const [changeTitlesAnimation, setChangeTitlesAnimation] = useState(false);

  const [galleryIndexPreviewBg, setGalleryIndexPreviewBg] = useState(
    data.galleries.edges[0].node.data.body[0].items[0].image.url
  );

  const previewGalleryReportage = data.galleries.edges.find(
    ({ node }) => node.data.section.slug === 'reportage'
  );

  const previewGalleryCorporate = data.galleries.edges.find(
    ({ node }) => node.data.section.slug === 'corporate'
  );

  const { setTheme } = useContext(AppContext);
  const { locale } = useContext(LocaleContext);

  useEffect(() => {
    let intId;

    function checkPaceDone() {
      const done = document.body.classList.contains('pace-done');
      if (done) {
        clearInterval(intId);
        setPaceDone(true);
      }
    }

    if (document.body.classList.contains('pace-done')) return;

    intId = setInterval(checkPaceDone, 50);

    return () => {
      clearInterval(intId);
    };
  }, []);

  useEffect(() => {
    setTheme('light');

    // If we come from a gallery
    if (
      typeof entry.state.from !== 'undefined' &&
      (entry.state.from === 'reportage' || entry.state.from === 'corporate')
    ) {
      setLoadingAnimationsCompleted(true);
      setChangeTitlesAnimation(true);
    } else if (location.state && location.state.disableAnimations) {
      // If we come from a section clicking the logo
      setFadeHome(true);
      setChangeTitlesAnimation(true);
      navigate(location.pathname, {
        state: { disableAnimations: false },
        replace: true,
      });
    } else {
      setLoadingAnimationsCompleted(false);
      setChangeTitlesAnimation(false);
      setFadeHome(false);
    }
  }, []);

  const heroRef = useRef();

  const triggerTransition = useTriggerTransition();

  // This variable doesn't update between renders, and holds our visible state.
  // We will need it while dragging and while scrolling to know where we are at.
  // We are using a ref (which holds its value between renders, and does not
  // trigger a render) because react-gesture (useDrag etc.) work outside of
  // the render cycle
  const activeSection = useRef(
    typeof entry.state.from !== 'undefined' &&
      (entry.state.from === 'reportage' || entry.state.from === 'corporate')
      ? entry.state.from
      : location.state &&
        ((location.state.activeSection &&
          location.state.activeSection === 'corporate') ||
          location.state.activeSection === 'reportage')
      ? location.state.activeSection
      : 'reportage'
  );

  // This value holds 'y' or 'x' and tells us if the user is trying
  // to drag horizontally or vertically
  const axis = useRef();

  // 'true' if Spring is auto-animating something. We use it to block further
  // drag events until Spring is done
  const isAnimating = useRef(false);

  // This variable holds the difference between the "collapsed" and "extended"
  // corporate width (and by reflection, the reportage width). If we ever
  // change the CSS update the value here and all else will work just as well.
  // This value is a percentage of the screen width! "120"/"80" -> see CSS
  const smallerWidth =
    typeof window === 'undefined' || window.innerWidth > 576 ? 120 : 70;
  const draggableDeltaX =
    typeof window === 'undefined'
      ? 88
      : 100 - (smallerWidth * 2 * 100) / window.innerWidth;

  const visibleSideWidth = (100 - draggableDeltaX) / 2;

  const onDragRest = ({ x, y }) => {
    // The user has dragged up, and the transition has completed
    if (y === -1) {
      isAnimating.current = false;
      triggerTransition({
        to:
          locale === 'en'
            ? `/${activeSection.current}`
            : `/${locale}/${activeSection.current}`,
        exit: {
          length: 0.1,
        },
        entry: {
          length: 0,
        },
      });
    } else if (y === 0) {
      if (x === 0) {
        isAnimating.current = false;
        // Apply reportage background to the gallery index preview
        setGalleryIndexPreviewBg(
          previewGalleryReportage.node.data.body[0].items[0].image.url
        );
      } else if (x === -1) {
        isAnimating.current = false;
        // Apply corporate background to the gallery index preview
        setGalleryIndexPreviewBg(
          previewGalleryCorporate.node.data.body[0].items[0].image.url
        );
      }
    }
  };

  // Spring stuff
  const [{ x, y }, set] = useSpring(() => ({
    x: activeSection.current === 'reportage' ? 0 : -1,
    y: 0,
    onRest: onDragRest,
    config: {
      clamp: true,
    },
  }));

  const transitionNextFrom = from => {
    const current = activeSection.current;
    let next = false;

    if (from === 'reportage' && current == 'reportage')
      next = 'enter-reportage-galleries';
    if (from === 'corporate' && current == 'reportage')
      next = 'switchto-corporate';
    if (from === 'corporate' && current == 'corporate')
      next = 'enter-corporate-galleries';
    if (from === 'reportage' && current == 'corporate')
      next = 'switchto-reportage';

    let xy = [];
    switch (next) {
      case 'enter-reportage-galleries':
        xy = [0, -1];
        break;

      case 'switchto-corporate':
        xy = [-1, 0];
        break;

      case 'enter-corporate-galleries':
        xy = [-1, -1];
        break;

      case 'switchto-reportage':
        xy = [0, 0];
        break;

      default:
        break;
    }

    if (next) {
      activeSection.current = from;
      set({
        x: xy[0],
        y: xy[1],
        onRest: onDragRest,
      });
    }
  };

  const bind = useDrag(
    ({
      last,
      down,
      movement: [xMov, yMov],
      distance,
      canceled,
      cancel,
      direction: [xDir, yDir],
      memo = [x.getValue(), y.getValue()],
    }) => {
      if (!axis.current) {
        if (Math.abs(xDir) > Math.abs(yDir)) axis.current = 'x';
        else if (Math.abs(yDir) > Math.abs(xDir)) axis.current = 'y';
      }

      if (isAnimating.current) {
        if (last) axis.current = null;
        return memo;
      }

      // Show drag cursor (we have to do this outside of the render cycle)
      if (down) heroRef.current.classList.add('is-dragging');
      else heroRef.current.classList.remove('is-dragging');

      set(() => {
        if (axis.current === 'x') {
          // It's -draggableDeltaX (negative) because we start by dragging left
          const startingX =
            activeSection.current === 'reportage' ? 0 : -draggableDeltaX;
          let x = down
            ? (xMov / window.innerWidth) * 100 + startingX
            : startingX;

          // This variable is true if we are dragging "the right way", i.e. if
          // in "reportage" we drag left to open "corporate", or viceversa
          const rightWay =
            (activeSection.current === 'reportage' && xDir < 0) ||
            (activeSection.current === 'corporate' && xDir > 0);

          // If we drag "the right way" at least 1/3 of the window
          // width cancel the drag...
          if (down && rightWay && distance > window.innerWidth / 3) cancel();

          // If we drag "the wrong way" for just 1/8 of the window
          // width cancel the drag...
          if (down && !rightWay && distance > window.innerWidth / 8) cancel();

          // ...and auto-complete (animate) the rest of the way
          if (canceled) {
            isAnimating.current = true;

            // If we were not dragging the right way return to the start,
            // otherwise complete the animation either towards the left
            // or to the right
            if (!rightWay) {
              x = startingX;
            } else {
              if (activeSection.current === 'reportage') {
                x = -draggableDeltaX;
                activeSection.current = 'corporate';
              } else {
                x = 0;
                activeSection.current = 'reportage';
              }
            }
          }

          // 0: initial (right); -1: dragged 70% left
          const relativeX = x / draggableDeltaX;

          return {
            x: relativeX,
            y: memo[1],
            onRest: onDragRest,
          };
        } else if (axis.current === 'y') {
          let y = down ? yMov / window.innerHeight : 0;

          // This variable is true if we are draggin "the right way", i.e. "up"
          const rightWay = yDir < 0;

          // If we drag "the right way" at least 1/3 of the window height
          // cancel the drag...
          if (down && rightWay && distance > window.innerHeight / 3) cancel();

          // ...and auto-complete (animate) the rest of the way
          if (canceled) {
            // If we were not dragging the right way return to the start,
            // otherwise complete the animation either towards the left
            // or to the right
            if (!rightWay) {
              y = 0;
            } else {
              y = -1;
            }
          }

          return {
            x: memo[0],
            y: y,
            onRest: onDragRest,
          };
        }
      });
      if (last) axis.current = null;
      return memo;
    }
  );

  let reportageClasses = 'category reportage';
  if (loadingAnimationsCompleted && paceDone)
    reportageClasses += ' remove-animation';
  if (fadeHome) reportageClasses += ' fade-animation';
  if (x.value === 0) reportageClasses += ' is-open';

  let corporateClasses = 'category corporate';
  if (loadingAnimationsCompleted && paceDone)
    corporateClasses += ' remove-animation';
  if (fadeHome) corporateClasses += ' fade-animation';
  if (x.value === -1) corporateClasses += ' is-open';

  return (
    <>
      <SEO title="Home" lang={locale} image={data.reportage.data.image.url} />
      <DragHint />
      <Div100vh>
        <animated.div
          className={
            changeTitlesAnimation ? 'hero change-titles-animation' : 'hero'
          }
          {...bind()}
          ref={heroRef}
          style={{
            transform: y.interpolate(
              y => `translateY(${clamp(y, -1, 0) * 100}%)`
            ),
          }}
        >
          <animated.div
            style={{
              width:
                typeof window === 'undefined' || !paceDone
                  ? null
                  : x.interpolate(
                      x =>
                        `${visibleSideWidth +
                          draggableDeltaX +
                          x * draggableDeltaX}%`
                    ),
            }}
            className={reportageClasses}
            onAnimationEnd={e => {
              // Only run if the animation happened to us
              if (e.target !== e.currentTarget) return;

              setLoadingAnimationsCompleted(true);
              // After this <div> animation completes we need another 1000ms
              // to allow for the underlying <h1> animation to run.
              setTimeout(() => {
                setChangeTitlesAnimation(true);
              }, 1000);
            }}
          >
            <Reportage
              x={x}
              title={data.reportage.data.title.text}
              bg={data.reportage.data.image.url}
              transitionNextFrom={transitionNextFrom}
              loadingAnimationsCompleted={loadingAnimationsCompleted}
              changeTitlesAnimation={changeTitlesAnimation}
            />
          </animated.div>
          <animated.div
            style={{
              width:
                typeof window === 'undefined' || !paceDone
                  ? null
                  : x.interpolate(
                      x => `${visibleSideWidth - x * draggableDeltaX}%`
                    ),
            }}
            className={corporateClasses}
          >
            <Corporate
              x={x}
              title={data.corporate.data.title.text}
              bg={data.corporate.data.image.url}
              transitionNextFrom={transitionNextFrom}
              loadingAnimationsCompleted={loadingAnimationsCompleted}
              changeTitlesAnimation={changeTitlesAnimation}
            />
          </animated.div>
        </animated.div>
        <GalleryIndexPreview
          {...bind()}
          y={y}
          background={galleryIndexPreviewBg}
        />
      </Div100vh>
    </>
  );
};

export default IndexPage;

export const IndexQuery = graphql`
  query IndexData {
    galleries: allPrismicGallery(
      sort: { fields: [data___order, id], order: [ASC, ASC] }
    ) {
      edges {
        node {
          data {
            section {
              slug
            }
            body {
              items {
                image {
                  url
                }
              }
            }
          }
        }
      }
    }
    reportage: prismicSection(slugs: { eq: "reportage" }) {
      data {
        title {
          text
        }
        image {
          url
        }
      }
    }
    corporate: prismicSection(slugs: { eq: "corporate" }) {
      data {
        title {
          text
        }
        image {
          url
        }
      }
    }
  }
`;
