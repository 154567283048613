import React from 'react';
import { animated } from 'react-spring';
import clamp from 'lodash-es/clamp'; // usage: clamp(value, min, max)

const GalleryIndexPreview = ({ background, y }) => {
  return (
    <div className="gallery-index-preview">
      <animated.img
        src={background}
        style={{
          transform: y.interpolate(
            y => `scale(${1 + (clamp(y, -1, 0) + 1) * 0.2})`
          ),
        }}
        alt=""
      />
      <div className="gallery-index-preview-background-overlay-preview"></div>
    </div>
  );
};

export default GalleryIndexPreview;
