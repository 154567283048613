import React, { useState } from 'react';
import { animated } from 'react-spring';
import clamp from 'lodash-es/clamp'; // usage: clamp(value, min, max)
import Arrow from '../Atoms/Arrow';
import Spanify from '../Spanify';

const Reportage = ({
  x,
  title,
  bg,
  transitionNextFrom,
  loadingAnimationsCompleted,
  changeTitlesAnimation,
}) => {
  const [hoverAnimationActive, setHoverAnimationActive] = useState(false);
  const AnimatedArrow = animated(Arrow);

  const translateY =
    typeof window === 'undefined' || window.innerWidth > 768
      ? 45
      : window.innerWidth > 576
      ? 25
      : 15;

  const translateX =
    typeof window === 'undefined' || window.innerWidth > 768
      ? 28
      : window.innerWidth > 576
      ? 28
      : 13;

  return (
    <>
      <img src={bg} className="category-background" alt=""></img>
      <animated.div
        className="category-background-overlay-reportage"
        style={{
          opacity: x.interpolate(x => {
            if (!loadingAnimationsCompleted && !changeTitlesAnimation)
              return '0.4';
            return `${0.4 - clamp(x, -1, 0) * 0.6}`;
          }),
        }}
      ></animated.div>
      <div className="category-title-wrapper">
        <animated.h1
          className={
            hoverAnimationActive ? 'hover-animation-active' : undefined
          }
          onClick={() => {
            transitionNextFrom('reportage');
          }}
          onMouseEnter={() => setHoverAnimationActive(true)}
          style={{
            opacity: x.interpolate(x =>
              Math.abs(x) < 0.01 || Math.abs(x) > 0.99 ? 1 : 0
            ),
            transform: x.interpolate(x =>
              x < -0.5
                ? `translate(${translateX}px, ${translateY}px) rotateZ(-90deg) scale(0.66)`
                : null
            ),
          }}
        >
          <span className="arrow">
            <AnimatedArrow
              down
              style={{
                transform: x.interpolate(x =>
                  x < -0.5 ? 'rotateZ(180deg)' : null
                ),
              }}
            />
          </span>
          <Spanify text={title} />
        </animated.h1>
      </div>
    </>
  );
};

export default Reportage;
